import { useState } from "preact/hooks";

export default function DarkModeToggle() {
  const [isDarkMode, setIsDarkMode] = useState(false); // Track dark mode state

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode); // Toggle dark mode state

    // Toggle the 'dark' class on the <html> element
    if (isDarkMode) {
      document.documentElement.classList.remove("dark");
      //console.log("Dark mode disabled");
    } else {
      document.documentElement.classList.add("dark");
      //console.log("Dark mode enabled");
    }
  };

  return (
    <label class="relative inline-flex items-center cursor-pointer">
      <input 
        type="checkbox" 
        checked={isDarkMode} 
        class="sr-only" 
        onChange={toggleDarkMode} 
      />
      <div class="w-14 h-7 bg-gray-200 dark:bg-gray-700 rounded-full relative transition-all peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800">
        {/* Sun and Moon Icons on each side */}
        <span class="absolute left-1 top-1 text-yellow-400">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <circle cx="12" cy="12" r="5" fill="currentColor" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2m0 14v2m9-9h-2m-14 0H3m16.364-6.364l-1.414 1.414M6.05 17.95l-1.414 1.414m12.728 0l-1.414-1.414M6.05 6.05L4.636 7.464" />
          </svg>
        </span>
        <span class="absolute right-1 top-1 text-blue-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
            <path d="M20.354 15.354A9 9 0 118.646 3.646 7 7 0 0020.354 15.354z" />
          </svg>
        </span>

        {/* Toggle Knob */}
        <div class={`w-6 h-6 bg-white rounded-full shadow-md transform ${isDarkMode ? "translate-x-7" : "translate-x-1"} transition-transform duration-300 ease-in-out`}></div>
      </div>
    </label>
  );
}
